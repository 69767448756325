import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { animateScroll as scroll } from "react-scroll";

let render = (state, controller) => {
    ReactDOM.render(
        <React.StrictMode>
            <App state={state} controller={controller}/>
        </React.StrictMode>,
        document.getElementById('root')
    );
    scroll.scrollToBottom();
}

export default render;