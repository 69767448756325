import * as axios from 'axios';
import lineTypes from '../Constants/ConsoleLineTypes';
const delay = ms => new Promise(res => setTimeout(res, ms));

class ConsoleController {
    static CCLines = require('../Models/ConsoleCommandsLines');

    constructor(state) {
        this.url = 'https://api.variussoft.ru/api/1.0/command/';
        //this.url = 'http://localhost:8001/api/1.0/command/';
        this.state = state;
        this.state.on('process-command', () => {
            this.processCommand(this.state.getCurrentCommand());
        });
    }

    async processingAnswer(response) {
        let answer = response.data;
        if (answer.error) {
            this.state.pushWithoutReaction(lineTypes.ERROR, answer.error);
            return;
        }
        for (let line of answer.lines) {
            await delay(50);
            if (line.needAnswer) {
                this.state.setIdForAnswer(line.key);
                this.state.pushWithoutReaction(lineTypes.ANSWER, line.text);
            } else {
                this.state.pushWithoutReaction(line.type, line.text);
            }
        }
    }

    processCommand(command) {
        if(command.trim().length < 2) {
            return;
        }

        this.state.clearCurrentCommand();
        if ((this.state.ID === 0)) {
            this.state.push(lineTypes.HISTORY, command);
            this.state.pushToHistory(command);
            command = command.replace('?', '--help');
            let url = this.url+command;

            axios.get(url).then(response => {
                this.processingAnswer(response).then(() => {
                    if (this.state.ID === 0) this.state.pushNew();
                });
            });
        } else {
            let url = this.url+'answer';
            this.state.pushToCurrentLine(lineTypes.IMPORTANT, command);

            axios.post(url, {key: this.state.ID, answer: command}).then(response => {
                this.state.ID = 0;
                this.processingAnswer(response).then(() => {
                    this.state.pushNew();
                });
            });
        }
    }

    async buildFirstData() {
        this.state.clear();
        this.state.push(lineTypes.SIMPLE, '');
        let url = this.url+'init';

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            url += ' --mobile';
        }

        await delay(1000);
        axios.get(url).then(response => {
            this.processingAnswer(response).then(() => {this.state.pushNew();});
        });
    }

    getData() {
        debugger;
        return this.state.get();
    }

    getState() {
        debugger;
        return this.state;
    }
}

export default ConsoleController;