import React from 'react';
import reportWebVitals from './reportWebVitals';
import stateClass from './Models/State';
import render from './render';
import ConsoleController from "./Controllers/ConsoleController";

const state = new stateClass();
let controller = new ConsoleController(state);
controller.buildFirstData();

render(state, controller);

state.on('update-data', () => {
    render(state, controller);
});

reportWebVitals();
