import lineTypes from '../Constants/ConsoleLineTypes';

const ConsoleLine = (data) => {
    let text = data.text.replaceAll(' ', '\u00A0');
    let res = [];
    text.replace(/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim, (m, link, text) => {
        res.push(link ?
            <a href={(link[0] === "w" ? "//" : "") + link} key={res.length} target="_blank">{link}</a> : text)
    });
    text = res;
    switch (data.type) {
        case lineTypes.HISTORY:
            return (<li>
                <span className="consoleLineHeader">userName@varius:></span>
                <span>{text}</span>
            </li>);
        case lineTypes.NEW:
            return (<li>
                <span className="consoleLineHeader">userName@varius:></span>
                <span>{data.currentCommand}</span>
                <span className="lineCursor">_</span>
            </li>);
        case lineTypes.SIMPLE:
            return (<li>{text}</li>);
        case lineTypes.IMPORTANT:
            return (<li className="importantLine">{text}</li>);
        case lineTypes.WARNING:
            return (<li className="warningLine">{text}</li>);
        case lineTypes.ANSWER:
            return (<li className="importantLine">
                <span>{text}</span>
                <span>{data.currentCommand}</span>
                <span className="lineCursor">_</span>
            </li>);
        case lineTypes.ERROR:
            return (<li className="errorLine">{text}</li>);
        default:
            return (<li/>);
    }
};

export default ConsoleLine;