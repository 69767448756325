import ConsoleCommandLine from "./ConsoleCommandLine";
import lineTypes from "../Constants/ConsoleLineTypes";
const EventEmitter = require('events');

class State extends EventEmitter {
    constructor() {
        super();
        this.ID = 0;

        this.historyLine = [];

        this.historyOfCommands = [];
        this.historyIndex = -1;
        this.currentCommand = '';

        window.addEventListener('keypress', (event) => {
            this.keyListener(event);
        });
        window.addEventListener('keydown', (event) => {
            this.systemKeyListener(event);
        });

        this.preloader = [
            'processing. Please wait /',
            'processing. Please wait -',
            'processing. Please wait \\',
            'processing. Please wait |',
            'processing. Please wait /',
            'processing. Please wait -',
            'processing. Please wait \\',
            'processing. Please wait |',
            'processing. Please wait / /',
            'processing. Please wait / -',
            'processing. Please wait / \\',
            'processing. Please wait / |',
            'processing. Please wait / /',
            'processing. Please wait / -',
            'processing. Please wait / \\',
            'processing. Please wait / |',
            'processing. Please wait / / /',
            'processing. Please wait / / -',
            'processing. Please wait / / \\',
            'processing. Please wait / / |',
            'processing. Please wait / / /',
            'processing. Please wait / / -',
            'processing. Please wait / / \\',
            'processing. Please wait / / |',
            'processing. Please wait / / /'
        ];
        this.preloaderIndex = 0;
    }

    getCurrentCommand() {
        return this.currentCommand;
    }

    clearCurrentCommand() {
        this.currentCommand = '';
    };

    systemKeyListener(event) {
        console.log(event.keyCode);
        if (event.keyCode === 8) {
            this.currentCommand = this.currentCommand.substring(0, this.currentCommand.length-1);
            this.emit('update-data');
        }

        if (event.keyCode === 38) {
            event.preventDefault();
            if (this.historyIndex === -1) this.historyIndex = this.historyOfCommands.length;
            if (this.historyIndex > -1) {
                this.currentCommand = this.historyOfCommands[--this.historyIndex];
                if (this.historyIndex === -1) this.historyIndex = 0;
            }

            this.emit('update-data');
        }

        if (event.keyCode === 40) {
            event.preventDefault();
            if (this.historyIndex === this.historyOfCommands.length) this.historyIndex = this.historyOfCommands.length - 1;
            if (this.historyIndex > -1) this.currentCommand = this.historyOfCommands[this.historyIndex++];

            this.emit('update-data');
        }
    }

    keyListener(event) {
        console.log(event.keyCode);

        if (event.keyCode === 13) {
            this.emit('process-command');
            return;
        }
        let letter = String.fromCharCode(event.charCode);
        this.currentCommand += letter;
        this.emit('update-data');
    }

    pushNew() {
        this.historyLine.push(new ConsoleCommandLine(lineTypes.NEW, ''));
        this.emit('update-data');
    }

    push(type, text) {
        this.historyLine.pop();
        this.historyLine.push(new ConsoleCommandLine(type, text));
        this.historyLine.push(new ConsoleCommandLine(lineTypes.SIMPLE, ''));
        this.preloaderTimer = setInterval(() => {
            this.pushToCurrentLine(lineTypes.SIMPLE, this.preloader[this.preloaderIndex]);
            this.preloaderIndex++;
            if (this.preloaderIndex === this.preloader.length) this.preloaderIndex = 0;
        }, 200);

        this.emit('update-data');
    }

    pushWithoutReaction(type, text) {
        if (this.preloaderTimer) {
            this.historyLine.pop();
            clearInterval(this.preloaderTimer);
            this.preloaderTimer = null;
        }
        this.historyLine.push(new ConsoleCommandLine(type, text));
        this.emit('update-data');
    }

    pushToHistory(command) {
        this.historyOfCommands.push(command);
    }

    pushToCurrentLine(type, text) {
        this.historyLine[this.historyLine.length - 1] = new ConsoleCommandLine(type, text);
        this.emit('update-data');
    }

    clear() {
        this.historyLine = [];
    }

    get() {
        return this.historyLine;
    }

    setIdForAnswer(id) {
        this.ID = id;
    }
}

export default State;