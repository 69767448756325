import './styles/Application.scss';
import ConsoleLine from './Components/ConsoleLine';
import React from "react";

let controller;

let App = (props) => {
    controller = props.controller;

    let linesComponents = props.state.get().map((line) => <ConsoleLine type={line.type} text={line.text}
                                                        key={Math.random()} currentCommand={props.state.getCurrentCommand()}/>);

    return (
        <div className="app">
            <ul className="mainConsoleList">
                {linesComponents}
            </ul>
        </div>
    );
};

export default App;
