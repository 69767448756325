class ConsoleLineTypes {
    static NEW = 'new';
    static HISTORY = 'history';
    static SIMPLE = 'simple';
    static ERROR = 'error';
    static WARNING = 'warning';
    static IMPORTANT = 'important';
    static ANSWER = 'answer';
}

export default ConsoleLineTypes;